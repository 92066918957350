<template>
<div>
  <a href="#" data-toggle="modal" data-target="#staticBackdrop" class="orange-button shadow px-3 py-1" style="position:fixed;bottom:20px;right:20px;z-index:8!important; font-size:medium">
    <i class="flaticon-filter mr-1 bold"></i>
    FILTRAR
  </a>
    <!-- Search Field Modal -->
  <section class="modal fade search_dropdown" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-hidden="true" style="z-index:999">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Filtros</h6>
          <a class="closer" data-dismiss="modal" aria-label="Close" href="#"><span aria-hidden="true"><img src="images/icons/close.svg" alt="close.svg"></span></a>
        </div>
        <div class="modal-body">
          <div class="popup_modal_wrapper">
            <div class="container">
              <div class="row">
                <!--
                <div class="col-lg-12">
                  <div class="btn-group btn-block" role="group" aria-label="Basic example">
                        <button type="button" class="btn" :class="[operacion=='venta' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('venta')">Venta</button>
                        <button type="button" class="btn" :class="[operacion=='alquiler' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('alquiler')">Alquiler</button>
                  </div>
                </div>-->
                <div class="col-12">
                  
                  <div class="btn-group btn-block mt-n2 mb-3" role="group" aria-label="Basic example">
                    <button type="button" class="btn" :class="[operacion=='venta' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('venta')">Venta</button>
                    <button type="button" class="btn" :class="[operacion=='alquiler' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('alquiler')">Alquiler</button>
                  </div>
                  
                </div>
                <div class="col-lg-6">
                  <div class="adv_src_pmodal">
                    <h6 class="title">Tipo de inmueble</h6>
                    <div class="ui_kit_select_box">
                      <multiselect v-model="tipos" :options="prop_tipos" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Tipos de inumeble" label="tipo" track-by="tipo" :preselect-first="false" :show-labels="false">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ tipos.length }} seleccionados</span></template>
                      </multiselect>
                      <!--<pre class="language-json"><code>{{ tipos  }}</code></pre>-->
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="adv_src_pmodal">
                    <h6 class="title">Habitaciones</h6>
                    <div class="ui_kit_select_box">
                      <select class="custom-select muted text-muted mb-3" v-model="habitaciones">
                        <option value="-1">Habitaciones</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">+4</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="adv_src_pmodal">
                    <h6 class="title">Ciudad</h6>
                    <div class="ui_kit_select_box">
                      <select class="custom-select mb20" @change="selectCiudad($event)" v-model="ciudad" >
                        <option value="-1" selected>Todas las ciudades</option>
                        <option v-for="(ciudad,index) in prop_ciudades" v-bind:key="index" :value="ciudad.cod_ciu">{{ciudad.city}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="adv_src_pmodal">
                    <h6 class="title">Zonas</h6>
                    <div class="ui_kit_select_box">
                      <multiselect v-model="zonas" :showNoOptions="true" :options="selected_zones" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Zonas" label="zone" track-by="zone" :preselect-first="false" :show-labels="false">
                          <template slot="selection"  slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ zonas.length }} Zonas</span></template>
                          <span slot="noOptions">No hay zonas para esta ciudad.</span>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb30-md">
                  <div class="adv_src_pmodal">
                    <h6 class="title">Area mínima (m&#178;)</h6>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Min. área" v-model="metros">
                    </div>
                    <!--
                    <div class="form-group">
                      <input type="text" class="form-control area_input float-right" placeholder="Max. m²" id="adv_max_area">
                    </div>-->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="adv_src_pmodal mb20">
                    <h6 class="title">Referencia</h6>
                    <input type="text" class="form-control" placeholder="Referencia" v-model="ref">
                  </div>
                </div>
                <div class="col-lg-12 mb30-md">
                  <div class="adv_src_pmodal">
                    <h6 class="title mb20">Price</h6>
                    <Slider v-model="range" :merge="merge" :format="format" :min="min_price" :max="max_price" :step="step" class="mt-5 slider-blue"/>
                  </div>
                </div>
              </div>
              <div class="row mt30">
                <div class="col-lg-12">
                  <div class="adv_src_pmodal">
                    <h6><span class="title">Extras</span></h6>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                  <div class="adv_src_pmodal mb20">
                    <div class="ui_kit_checkbox">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM1" v-model="piscina">
                        <label class="custom-control-label" for="customCheckM1">Piscina</label>
                      </div>
                      
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM4" v-model="terraza">
                        <label class="custom-control-label" for="customCheckM4">Terraza</label>
                      </div>

                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM8" v-model="obranueva">
                        <label class="custom-control-label" for="customCheckM8">Obra nueva</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                  <div class="adv_src_pmodal mb20">
                    <div class="ui_kit_checkbox">

                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM2" v-model="parking">
                        <label class="custom-control-label" for="customCheckM2">Parking</label>
                      </div>

                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM6" v-model="muebles">
                        <label class="custom-control-label" for="customCheckM6">Amueblado</label>
                      </div>

                      
                      <!--
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM9">
                        <label class="custom-control-label" for="customCheckM9">Refrigerator</label>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                  <div class="adv_src_pmodal mb20">
                    <div class="ui_kit_checkbox">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM3" v-model="ascensor">
                        <label class="custom-control-label" for="customCheckM3">Ascensor</label>
                      </div>

                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM7" v-model="urbanizacion">
                        <label class="custom-control-label" for="customCheckM7">Urbanización</label>
                      </div>

                      <!--
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheckM9">
                        <label class="custom-control-label" for="customCheckM9">Refrigerator</label>
                      </div>-->
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="adv_src_pmodal">
                    <button class="btn btn-block btn-thm orange-button" @click="searchProperties" data-dismiss="modal" aria-label="Close"><span class="fa fa-search mr15"></span>Filtrar propiedades</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

	<!-- Listing Grid View -->
	<section class="our-listing pb30-991" style="z-index:0!important">
		<div class="container">
      <div class="row">
        <div class="col-3 d-none d-xl-inline-block">
          <div class="sidebar_listing_list">
              <div class="sidebar_advanced_search_widget">
                <ul class="sasw_list mb0">
                  <li><h4 class="mb0">Ecuentra tu nuevo hogar</h4></li>
                  <li>
                    <div class="search_option_two mt-3">
                      <div class="btn-group btn-block" role="group" aria-label="Basic example">
                        <button type="button" class="btn" :class="[operacion=='venta' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('venta')">Venta</button>
                        <button type="button" class="btn" :class="[operacion=='alquiler' ? 'btn-primary': 'btn-outline-primary']" @click="changeOperation('alquiler')">Alquiler</button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_two mt-3">
                      <label>Tipos de inmueble:</label>
                      <div class="sidebar_select_options">
                        <multiselect v-model="tipos" :options="prop_tipos" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Tipos de inumeble" label="tipo" track-by="tipo" :preselect-first="false" :show-labels="false" @tag="addTag">
                          <!--<template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ tipos.length }} Tipos</span></template>-->
                        </multiselect>
                        <!--<pre class="language-json"><code>{{ tipos  }}</code></pre>-->
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_two mt-3">
                      <div class="sidebar_select_options">
                        <label>Ciudad :</label>
                        <select class="custom-select muted text-muted mb-3" v-model="ciudad" @change="selectCiudad($event)">
                          <option value="-1">Todas las ciudades</option>
                          <option v-for="(ciudad,index) in prop_ciudades" v-bind:key="index" :value="ciudad.cod_ciu" >{{ciudad.city}}</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_two mt-3">
                      <div class="sidebar_select_options">
                        <label class="typo__label">Zonas: </label>
                        <multiselect v-model="zonas" :showNoOptions="true" :options="selected_zones" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Zonas" label="zone" track-by="zone" :preselect-first="false" :show-labels="false">
                          <template slot="selection"  slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ zonas.length }} Zonas</span></template>
                          <span slot="noOptions">No hay zonas para esta ciudad.</span>
                        </multiselect>
                        <!--<pre class="language-json"><code>{{ zonas  }}</code></pre>-->
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_two mt-3">
                      <label for="">Rango de precio</label>
                      <Slider v-model="range" :merge="merge" :format="format" :min="min_price" :max="max_price" :step="step" class="mt-5 slider-blue"/>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_two mt-3">
                      <label for="">Metros</label>
                      <div class="from-group" >
                        <input type="text" class="form-control" placeholder="Min. área" v-model="metros">
                      </div>
                    </div>
                  </li>
                  <li class="search_option_two mt-3">
                    <div class="sidebar_select_options">
                      <label>Habitaciones :</label>
                      <select class="custom-select muted text-muted mb-3" v-model="habitaciones">
                        <option value="-1">Habitaciones</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">+4</option>
                      </select>
                    </div>
                  </li>
        
                  <li>
                    <div class="sidebar_accordion_widget mt10">
                      <div id="accordion" class="panel-group">
                        <div class="panel">
                          <div class="panel-heading">
                            <h4 class="panel-title other_fet">
                              <a href="#panelBodyRating" class="accordion-toggle link" data-toggle="collapse" data-parent="#accordion" style="text-decoration-color:#0D263B"><i class="icon fa fa-plus" style="color:#0D263B"></i> Otras características</a>
                            </h4>
                          </div>
                          <div id="panelBodyRating" class="panel-collapse collapse">
                            <div class="panel-body">
                              <ul class="ui_kit_checkbox selectable-list">
                                
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM1" v-model="piscina">
                                    <label class="custom-control-label" for="customCheckM1">Piscina</label>
                                  </div>
                                </li>
                                <li class="mb0">  
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM4" v-model="terraza">
                                    <label class="custom-control-label" for="customCheckM4">Terraza</label>
                                  </div>
                                </li>
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM8" v-model="obranueva">
                                    <label class="custom-control-label" for="customCheckM8">Obra nueva</label>
                                  </div>
                                </li>
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM2" v-model="parking">
                                    <label class="custom-control-label" for="customCheckM2">Parking</label>
                                  </div>
                                </li>
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM6" v-model="muebles">
                                    <label class="custom-control-label" for="customCheckM6">Amueblado</label>
                                  </div>
                                </li>
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM3" v-model="ascensor">
                                    <label class="custom-control-label" for="customCheckM3">Ascensor</label>
                                  </div>
                                </li>
                                <li class="mb0">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheckM7" v-model="urbanizacion">
                                    <label class="custom-control-label" for="customCheckM7">Urbanización</label>
                                  </div>
                                </li>
                                <li class="mt10">
                                  <div class="search_option_two">
                                    <label for="">Ref:</label>
                                    <div class="from-group" >
                                      <input type="text" class="form-control" placeholder="Referencia" v-model="ref">
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="search_option_button text-center mt25">
                      <button type="submit" class="btn btn-block btn-thm mb25 orange-button" @click="searchProperties()">Buscar</button>
                      <ul class="mb0">
                        <li class="list-inline-item mb0"><button style="text-decoration-color:#0D263B;border:none;" @click="clearSearch()"><span class="vam flaticon-return mr10"></span> Limpiar Filtros</button></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="col-12 col-xl-9">
          <div class="row justify-content-between">
            <div class="listing_filter_row dif db-767">
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-5">
                <div class="left_area tac-xsd mb30-767">
                  <p class="mb0 mt10"><span class="heading-color">Mostrando {{total_elem}} inmuebles</span></p>
                </div>
              </div>
              <div class="col-sm-12 col-md-8 col-lg-8 col-xl-7">
                <div class="row justify-content-end">
                  <span>Ordenar por:</span>
                  <select class="col-6 col-lg-3 custom-select muted text-muted mb-3 mx-3 mx-lg-0 ml-lg-3" @change="changeOrder($event)" >
                    <option value="-1">Nuevos primero</option>
                    <option value="1">Precio de menor a mayor</option>
                    <option value="2">Precio de mayor a menor</option>
                    <option value="3">Más antiguos primero</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <Property v-for="(inmu,index) in inmuebles" v-bind:key="index"
              :idProp="inmu.cod_ofer"
              :image="inmu.foto"
              :tipo="inmu.nbtipo"
              :dorm="inmu.habitaciones+inmu.habdobles"
              :banyos="inmu.banyos"
              :sup="inmu.m_cons"
              :referencia="inmu.ref"
              :precioInmo="inmu.precioinmo"
              :precioAlq="inmu.precioalq"
              :precioCom="inmu.outlet"
              :ciudad="inmu.ciudad"
              :operacion="inmu.keyacci"
              :zona="inmu.zona"
              :numFotos="inmu.numfotos"
              :colLg="6"
              :colXl="4"
            />
            <div class="col-lg-12">
              <div class="mbp_pagination mt10">
                <ul class="page_navigation">
                  <li class="page-item"><button class="page-link" @click="goToPage(pag-1)"><span class="fa fa-arrow-left"></span></button></li>
                  <li class="page-item" v-bind:class="(i==pag)?'active':''" v-for="i in num_pags" v-if="i==1||i==num_pags||(pag<=(i+3)&&pag>=(i-3))">
                    <button class="page-link" v-if="(i<=(pag+1)&&i>=(pag-1))||i==1||i==num_pags||(i==(1+2)&&pag==1)||(i==(num_pags-2)&&pag==num_pags)" @click="goToPage(i)">{{i}}</button>
                    <a href="" v-else-if="(i==4&&pag==1)||(i==(num_pags-3)&&pag==num_pags)||(i==(pag+2)||(i==pag-2))">...</a>
                  </li>
                  <li class="page-item"><button class="page-link" @click="goToPage(pag+1)" ><span class="fa fa-arrow-right" ></span></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
		</div>
	</section>
</div>
 
</template>

<script>
import Property from '@/components/Property.vue'
import engineFields from '@/engine_fields.json'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import Multiselect from 'vue-multiselect'

export default {
    components:{
        Property,
        Slider,
        Multiselect
    },
    data(){
        return {
            min_price:0,
            max_price:1500000,
            step:1000,
            range: [0, 1500000],
            merge:1000,
            format: {
              suffix: '€',
            },
            //busqueda
            tipos:[],
            ciudad:-1,
            zonas:[],
            ref:null,
            preciodesde:null,
            preciohasta:null,
            metros:null,
            habitaciones:-1,
            //Operaciones
            operacion:null,
            //Extras
            piscina:false,
            parking:false,
            ascensor:false,
            terraza:false,
            muebles:false,
            urbanizacion:false,
            obranueva:false,
            //Páginas
            num_pags:0,
            pag:1,
            total_elem:0,
            //Ordenacion
            orden:-1,
            //campos de inmovilla
            prop_tipos:engineFields.tipos,
            prop_ciudades:engineFields.ciudades,
            inmuebles:[],
            selected_zones:[]
            
        }
    },
    created(){
      window.scrollTo(0, 0);
      //busqueda
      var cod_tipos=(this.$route.query.tipos!=null)?this.$route.query.tipos.split(',').map((element)=>{return parseInt(element)}):[]
      for(var cod of cod_tipos) {
        for(var stipo of this.prop_tipos){
          if(stipo.cod_tipo==cod){
            console.log(stipo)
            this.tipos.push(stipo);
          }
        }
      }
      //zonas de la ciudad
      if(this.$route.query.ciudad != null && this.$route.query.ciudad != -1){
        this.ciudad=parseInt(this.$route.query.ciudad)
        var ciudad_json = this.prop_ciudades.filter(element=>element.cod_ciu===parseInt(this.$route.query.ciudad))
        this.selected_zones=ciudad_json[0].zonas
      }
      
      //

      var cod_zonas=(this.$route.query.zonas!=null) ? this.$route.query.zonas.split(',').map((element)=>{return parseInt(element)}) : []
      for(var cod of cod_zonas) {
        for(var szona of this.selected_zones){
          if(szona.cod_zona==cod){
            this.zonas.push(szona);
          }
        }
      }
      //Operaciones
      switch (this.$route.query.operacion) {
          case 'venta':
              this.operacion='venta';
              this.min_price=0;
              this.max_price=1500000;
              this.step=1000;
              this.range=[0,1500000]
              break;
          case 'alquiler':
              this.operacion='alquiler';
              this.min_price=0;
              this.max_price=2000;
              this.step=100;
              this.range=[0,2000]
              break;
          default:
              this.operacion=null;
              this.min_price=0;
              this.max_price=1500000;
              this.step=1000;
              this.range=[0,1500000]
              break;
      }

      this.ref=this.$route.query.ref
      this.preciodesde=this.$route.query.preciodesde 
      this.preciohasta=this.$route.query.preciohasta
      this.metros=this.$route.query.metros
      this.habitaciones=(this.$route.query.habitaciones != null)? this.$route.query.habitaciones : -1;

      this.range[0] = (this.preciodesde!=null)? this.preciodesde : this.range[0] ; 
      this.range[1] = (this.preciohasta!=null)? this.preciohasta : this.range[1] ; 
      //Extras
      this.piscina=this.$route.query.piscina=='true'?true:false
      this.parking=this.$route.query.parking=='true'?true:false
      this.ascensor=this.$route.query.ascensor=='true'?true:false
      this.terraza=this.$route.query.terraza=='true'?true:false
      this.muebles=this.$route.query.muebles=='true'?true:false
      this.urbanizacion=this.$route.query.urbanizacion=='true'?true:false
      this.obranueva=this.$route.query.obranueva=='true'?true:false
      this.pag=this.$route.query.pag>1? this.$route.query.pag : 1
      this.orden= this.$route.query.order
      this.getInmuebles()
    }, 
    methods:{
        async getInmuebles(){
            try {
                const inmueblesDB = await this.axios.get('inmovilla/inmuebles', { params: this.$route.query });
                var counts = (inmueblesDB.data.paginacion!=null)?inmueblesDB.data.paginacion.shift():null;
                this.num_pags = (counts!=null && counts.total!=0)?Math.ceil(counts.total/12):1;
                this.total_elem = counts.total!=null?counts.total:0;
                await inmueblesDB.data.paginacion?.forEach(element => {
                    this.inmuebles.push(element)
                });
            } catch (error) {
                console.log(error)
            }
        },
        goToPage(num){
          if(num<=this.num_pags&&num>=1){
            this.pag=num;
          }else{
            this.pag=1;
          }
          this.refreshUrl();
          this.inmuebles=[];
          this.getInmuebles();
          window.scrollTo(0, 0);
        },
        changeOperation(operation){
            this.clearFilters()
            this.operation=operation
            if(operation!=null){
              this.$router.push({
                  path:'/listado-inmuebles',
                  query: {
                    operacion: operation
                  },
              })
            }
            switch (operation) {
              case 'venta':
                this.operacion='venta';
                this.min_price=0;
                this.max_price=1500000;
                this.step=1000;
                this.range=[0,1500000]
                break;
              case 'alquiler':
                this.operacion='alquiler';
                this.min_price=0;
                this.max_price=2000;
                this.step=100;
                this.range=[0,2000]
                break;
              default:
                this.operacion=null;
                this.min_price=0;
                this.max_price=1500000;
                this.step=1000;
                this.range=[0,1500000]
                break;
            }
            this.inmuebles=[]
            this.getInmuebles()
        },
        selectCiudad(event){
          if (parseInt(event.target.value)!=-1) {
             var ciudad = this.prop_ciudades.filter(element=>element.cod_ciu===parseInt(event.target.value))
             this.zonas = []
            if(ciudad.length > 0){
              this.ciudad=ciudad[0].cod_ciu
              this.selected_zones=ciudad[0].zonas
            }
          } else{
            this.selected_zones=[]
          }
        },
        searchProperties(){
          this.pag=1
          this.preciodesde=(this.range[0]>this.min_price)?this.range[0]:null;
          this.preciohasta=(this.range[1]<this.max_price)?this.range[1]:null;
          this.refreshUrl();
          //nueva búsqueda
          this.inmuebles = []
          this.getInmuebles()
        },
        refreshUrl(){

          this.$router.replace({'query': null});

          var query = {}
          if(this.operacion!=null){
            query.operacion=this.operacion
          }

          if(this.tipos.length>0){
            query.tipos= JSON.stringify(this.tipos.map((x)=>{return x.cod_tipo})).slice(1,-1)
          }

          if(this.ciudad!=null&&this.ciudad!=-1){
            query.ciudad= this.ciudad
          }

          if(this.zonas.length>0){
            query.zonas=JSON.stringify(this.zonas.map((x)=>{return x.cod_zona})).slice(1,-1)
          }

          if(this.ref!=null){
            query.ref=this.ref
          }
          
          if(this.preciodesde != null ){
            query.preciodesde = this.preciodesde

          }
          if (this.preciohasta != null) {
            query.preciohasta = this.preciohasta
          }

          if(this.metros!=null){
            query.metros=this.metros
          }

          if(this.habitaciones != null && this.habitaciones!=-1){
            query.habitaciones = this.habitaciones
          }

          if(this.piscina){
            query.piscina=true
          }
          if(this.parking){
            query.parking=true
          }
          if(this.ascensor){
            query.ascensor=true
          }
          if(this.terraza){
            query.terraza=true
          }
          if(this.muebles){
            query.muebles=true
          }
          if(this.urbanizacion){
            query.urbanizacion=true
          }
          if(this.obranueva){
            query.obranueva=true
          }

          if(this.pag!=1){
            query.pag=this.pag
          }

          if(this.orden!=-1){
            query.order=this.orden
          }

          this.$router.push({
              path:'/listado-inmuebles',
              query:query
          })
        },
        clearFilters(){
            this.pag=1
            this.tipos=[]
            this.ciudad=-1
            this.zonas=[]
            this.ref=null
            this.preciodesde=null
            this.preciohasta=null
            this.metros=null
            this.habitaciones=null
            this.operacion=null
            this.piscina=false
            this.parking=false
            this.ascensor=false
            this.terraza=false
            this.muebles=false
            this.urbanizacion=false
            this.obranueva=false
            this.min_price=200
            this.max_price=100000
            this.range= [200, 100000]
            this.refreshUrl()
        },
        addTag (newTag) {
          const tag = {
            name: newTag,
            code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
          }
          this.options.push(tag)
          this.value.push(tag)
        },
        changeOrder(event){
          if (parseInt(event.target.value)!=null) {
            this.pag=1;
            this.orden = parseInt(event.target.value)
            this.refreshUrl()
            this.searchProperties()
          }
        },
        clearSearch(){
          var op = this.operacion;
          this.clearFilters();
          this.operacion = op;
          this.refreshUrl();
          this.searchProperties();
        }
    }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.slider-blue {
  --slider-connect-bg: #0D263B;
  --slider-tooltip-bg: #0D263B;
  --slider-handle-ring-color: #3B82F630;
}
.multiselect__option:hover,.multiselect__option--highlight, .multiselect__tags-wrap * {
  background: #0D263B !important;
}
.multiselect__option--selected:hover{
  background: #0D263B !important;
  color: #0D263B!important;
}
li {
     margin-bottom: 0px !important; 
}
.page-link{
  height:30px !important;
  width:30px !important;
  line-height: 30px !important;
}
.btn-primary{
  background-color: #0D263B!important;
  border-color : #0D263B !important;
}
.btn-outline-primary{
  border-color : #0D263B !important;
  color: #0D263B !important;
}
.btn-outline-primary:hover{
  border-color : #0D263B !important;
  background-color: #0D263B !important;
  color:white !important;
}


</style>