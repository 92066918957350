<template>
<div class="mt-n2">
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert alart_style_three fade show" role="alert" v-if="error_form!=''"> {{error_form}}</div>
                <div class="alert alart_style_one fade show" role="alert" v-if="message_form!=''"> {{message_form}}</div>
                <div class="form">
                    <h4>Vende tu inmueble con nosotros</h4>
                    <multiselect class="mb-3" v-model="tipo" deselect-label="" :close-on-select="true" track-by="tipo" label="tipo" placeholder="Tipo de inmueble" :options="prop_tipos" :searchable="true" :allow-empty="false" :show-labels="false">
                        <template slot="singleLabel" slot-scope="{ option }">{{ tipo.tipo }}</template>
                    </multiselect>
                    <multiselect class="mb-3" v-model="operacion" deselect-label="" :close-on-select="true" track-by="name" label="name" placeholder="Operación" :options="prop_operacion" :searchable="false" :allow-empty="false" :show-labels="false">
                        <template slot="singleLabel" slot-scope="{ option }">{{ operacion.name }}</template>
                    </multiselect>
                    <input type="text" class="form-control mb-3" placeholder="Ciudad" v-model="ciudad">
                    <input type="text" class="form-control mb-3" placeholder="Nombre" v-model="nombre">
                    <input type="text" class="form-control mb-3" placeholder="Teléfono" v-model="tel">
                    <input type="text" class="form-control mb-3" placeholder="Email" v-model="email">
                    <div class="ui_kit_checkbox">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="contact_check">
                            <label class="custom-control-label" for="customCheck1">He leído y acepto los<router-link to="politica-de-privacidad"> términos y condiciones de privacidad</router-link></label>
                        </div>
                    </div>
                    <button class="btn btn-block orange-button" @click="sendContact()">Contactar</button>
                </div>
            </div>
            </div>
        </div>
    </div>

    <section class="intro home-one" >
        <div class="container">
            <div class="" style="background-color:transparent !important;z-index:1!important;opacity:;">
            <div class="mobile-menu" style="background-color:transparent !important">
            <div class="header" style="background-color:transparent !important">
                <div class="main_logo_home2 text-center" style="margin-top:5px !important"><img class="nav_logo_img img-fluid mt10" style="z-index:999 !important;opacity:1 !important" src="../assets/static/avanza-white.png" alt="header-logo.svg"></div>
                <ul class="menu_bar_home2" >
                <li class="list-inline-item"><a class="custom_search_with_menu_trigger msearch_icon" href="#"></a></li>
                <li class="list-inline-item"><!--<a class="muser_icon" href="#" data-toggle="modal" data-target="#logInModal"><span class="flaticon-user"></span></a>--></li>
                <li class="list-inline-item"><a style="margin-top:10px !important" class="menubar" href="#menu"><span></span></a></li>
                </ul>
            </div>
            </div>
            </div>
            <div class="row intro-content">
                <div class="col-12 col-lg-4">
                    <h2 class="text-white mb-5">
                        ¿Vender tu piso rápido? <br>
                        <span style="background-color:#E45726 !important;color:white">Se puede.</span>
                    </h2>
                    <p class="text-white" style="font-size:18px">
                        Si quieres vender tu piso de manera rápida, fácil y profesional, estás en el lugar adecuado.
                    </p>
                    <div class="row mx-1 mb-5">
                        <div class="col mr-3 rounded" style="background-color:rgba(255, 255, 255, 0.3);">
                            <div class="row justify-content-center my-4">
                                <img src="../assets/static/clarity_house-line.png" alt="" class="d-block fluid-img">
                                <p class="col-12 text-center mt-2 sd-block text-white">
                                    Mejor precio
                                </p>
                            </div>
                        </div>
                        <div class="col mr-3 rounded" style="background-color:rgba(255, 255, 255, 0.3);">
                            <div class="row justify-content-center my-4">
                                <img src="../assets/static/ph_timer-light.png" alt="" class="d-block fluid-img">
                                <p class="col-12 text-center mt-2 sd-block text-white">
                                    Rápidos
                                </p>
                            </div>
                        </div>
                        <div class="col rounded" style="background-color:rgba(255, 255, 255, 0.3);">
                            <div class="row justify-content-center my-4">
                                <img src="../assets/static/octicon_verified-24.png" alt="" class="d-block fluid-img">
                                <p class="col-12 text-center mt-2 sd-block text-white">
                                    Profesionales
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4"></div>
                <div class="col-12 col-lg-4" id="form">
                    <div class="alert alart_style_three fade show" role="alert" v-if="error_form!=''"> {{error_form}}</div>
                    <div class="alert alart_style_one fade show" role="alert" v-if="message_form!=''"> {{message_form}}</div>
                    <div class="form">
                        <h4>Vende tu inmueble con nosotros</h4>
                        <multiselect class="mb-3" v-model="tipo" deselect-label="" :close-on-select="true" track-by="tipo" label="tipo" placeholder="Tipo de inmueble" :options="prop_tipos" :searchable="true" :allow-empty="false" :show-labels="false">
                            <template slot="singleLabel" slot-scope="{ option }">{{ tipo.tipo }}</template>
                        </multiselect>
                        <multiselect class="mb-3" v-model="operacion" deselect-label="" :close-on-select="true" track-by="name" label="name" placeholder="Operación" :options="prop_operacion" :searchable="false" :allow-empty="false" :show-labels="false">
                            <template slot="singleLabel" slot-scope="{ option }">{{ operacion.name }}</template>
                        </multiselect>
                        <input type="text" class="form-control mb-3" placeholder="Ciudad" v-model="ciudad">
                        <input type="text" class="form-control mb-3" placeholder="Nombre" v-model="nombre">
                        <input type="text" class="form-control mb-3" placeholder="Teléfono" v-model="tel">
                        <input type="text" class="form-control mb-3" placeholder="Email" v-model="email">
                        <div class="ui_kit_checkbox">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="contact_check">
                                <label class="custom-control-label" for="customCheck1">He leído y acepto los<router-link to="politica-de-privacidad"> términos y condiciones de privacidad</router-link></label>
                            </div>
                        </div>
                        <button class="btn btn-block orange-button" @click="sendContact">Contactar</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="d-lg-none" style="heigth:100px;">

    </section>

    <section class="pb70 mt-5 mt-lg-0">
        <div class="container mt-5 mt-lg-0">
            <div class="row justify-content-center mb-5">
                <h2 class="text-center mt-5 mt-lg-0">¿Por qué Avanza?</h2>
            </div>
            <div class="row justify-content-between">
                <div class="col-12 col-lg-6">
                    <div class="shadow m-lg-2 p-5">
                        <h3>Garantía <span class="d-none">Avanza</span></h3>
                        <img src="../assets/static/avanza-color.png" alt="Avanza" class="mb-5" >
                        <ul>
                            <li><img class="mt-n2" src="../assets/static/tick.png" height="20" alt=""> <span style="font-size:18px;color:#443E3FC9;"> Recibe visitas de clientes potenciales </span></li>
                            <li><img class="mt-n2" src="../assets/static/tick.png" height="20" alt=""> <span style="font-size:18px;color:#443E3FC9;"> Vende al mejor precio </span></li>
                            <li><img class="mt-n2" src="../assets/static/tick.png" height="20" alt=""> <span style="font-size:18px;color:#443E3FC9;"> Asesoramiento jurídico </span></li>
                            <li><img class="mt-n2" src="../assets/static/tick.png" height="20" alt=""> <span style="font-size:18px;color:#443E3FC9;"> Te acompañamos hasta notario </span></li>
                            <li><img class="mt-n2" src="../assets/static/tick.png" height="20" alt=""> <span style="font-size:18px;color:#443E3FC9;"> Servicios post-venta </span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="shadow m-lg-2 p-5">
                        <h3 class="mb-5" style="color:#767676;">Otras <br> inmobiliarias.</h3>
                        <ul class="mt-2 mb-2">
                            <li><img class="mt-n2" src="../assets/static/cross.png" height="20" alt=""> <span style="font-size:18px;color:#AEAEAEC9"> No conoce la última técnología real estate. </span></li>
                            <li><img class="mt-n2" src="../assets/static/cross.png" height="20" alt=""> <span style="font-size:18px;color:#AEAEAEC9"> Comisiones altas sin resultados claros. </span></li>
                            <li><img class="mt-n2" src="../assets/static/cross.png" height="20" alt=""> <span style="font-size:18px;color:#AEAEAEC9"> Poca resolución en gestiones post venta. </span></li>
                            <li><img class="mt-n2" src="../assets/static/cross.png" height="20" alt=""> <span style="font-size:18px;color:#AEAEAEC9"> Inseguridad jurídica. </span></li>
                            <li><img class="mt-n2" src="../assets/static/cross.png" height="20" alt=""> <span style="font-size:18px;color:#AEAEAEC9"> Venta no garantizada. </span></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div class="row mt-5 justify-content-center mb-5">

                    <button class="btn py-3 px-5 orange-button" data-toggle="modal" data-target="#exampleModal">Solicita asesoramiento GRATIS</button>

            </div>
        </div>
    </section>

    <section class="pb70" id="banner1">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-6">
                    <p>
                        <span style="font-size:40px;font-weight:700;color:#E45726;">+500 inmuebles vendidos</span> <br>
                        <span style="font-size:20px;color:#232039;">en los últimos cuatro años</span>
                    </p>
                </div>
            </div>  
        </div>
    </section>

    <section class="pb70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <h3 class="text-center mb-5" style="font-weight:400">
            Trabajamos con más de <span style="background-color:#E45726;color:white;">25 portales...</span>
          </h3>
          <Carousel :perPageCustom="[[0,1],[480, 2], [768, 3], [992,4]]" :autoplay="true">
          <Slide class="text-center px-4">
            <img src="../assets/static/partner.png" alt="" class="fluid-img">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/partner.png" alt="" class="fluid-img">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/partner.png" alt="" class="fluid-img">
          </Slide>
          <Slide class="text-center px-4">
            <img src="../assets/static/partner.png" alt="" class="fluid-img">
          </Slide>

          </Carousel>  
        </div>
      </div>
    </div>
    </section>

    <section class="pb70">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 mb-5">
                    <div class="container">
                        <img src="../assets/static/house.png" alt="" class="fluid-img">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <h4>Descubre nuestro <span style="color:#E45726;">plan de venta rápida.</span></h4>
                    <br>
                    <p style="font-weight:700;color:#747474;">
                        <span style="color:#E45726;">Rápido y fácil.</span> Con nuestro plan estratégico de venta,
                        garantizamos que tu inmueble se venderá en un tiempo récord.
                    </p>
                    <br>
                    <ul>
                        <li style="font-size:18px;color:#C4C4C4;">🏡 Valoramos tu piso sin compromiso.</li>
                        <li style="font-size:18px;color:#C4C4C4;">😊 Te acompañaremos en todo el proceso.</li>
                        <li style="font-size:18px;color:#C4C4C4;">⚡️ Vendemos tu piso en tiempo récord.</li>
                        <li style="font-size:18px;color:#C4C4C4;">💸 Te conseguiremos el mejor precio.</li>
                        <li style="font-size:18px;color:#C4C4C4;">📊 Nos encargamos de todo el papeleo.</li>
                        <li style="font-size:18px;color:#C4C4C4;">💬 Te representamos en la negociación.</li>
                    </ul>
                </div>
            </div>
            <div class="row mt-5 justify-content-center mb-5">
                
                    <a href="/" class="btn mt-5 py-3 px-5 orange-button" data-toggle="modal" data-target="#exampleModal">Quiero vender mi piso en tiempo récord</a>
                
            </div>
        </div>
    </section> 

    <section class="pb70" id="opiniones">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6">
          <h2 class="text-white text-center mb-5" style="font-weight:400">
            <span style="color:#E45726;font-weight:800">+ 5000 clientes</span> han confiado en Avanza para vender su inmueble
          </h2>
          <Carousel :perPageCustom="[[0,1]]" :autoplay="true">
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Ana Maria Valero
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Mi experiencia con Cristina fue fabolusa, gran profesional, trato impecable, atenta y comprometida con la necesidad del cliente. Me resultó muy cómodo gestionar todo con ella pues tiene una gran formación en el sector y ganó mi confianza desde el inicio. Volvería a contar con esta agencia inmobiliaria y con los servicios de esta trabajadora. Grandes asesores del sector inmobiliario
            </p>
          </Slide>  
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Irene Romero
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Trato inmejorable, cercano y cordial, nos atendió José Luis y quedamos encantados con él, muy amable y atento,rapidez y profesionalidad. Un acierto trabajar con ellos.
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Raquel Aparicio
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              José Luis ha tenido un trato excelente, totalmente a disposición y facilitando todos los trámites. Sin duda, si tenemos que realizar alguna otra compra-venta, acudiremos aquí.            
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Rafael Hernandez Gandia
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star-half text-warning"></span>
            </span>
            <p class="text-white">
              Muy contentos con todo el equipo, te asesoran muy bien en todo, te resuelven dudas y lo más importante, en nuestro caso alquiler, se aseguran a la hora de posibles candidatos,para que den el mejor perfil que andábamos buscando
            </p>
          </Slide>
          <Slide class="text-center px-4">
            <span class="text-white" style="font-weight:700;font-size:16px">
              Cristina Molina García
              <span class="fa fa-star mr-1 ml-2 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star mr-1 text-warning"></span>
              <span class="fa fa-star text-warning"></span>
            </span>
            <p class="text-white">
              Recomiendo Avanza, por que no sólo te ofrece un inmueble, sino un acompañamiento en todo el proceso de principio hasta la firma de la compra.  Te ayuda en el filtro de búsqueda, facilita la documentación necesaria y se involucra en lo posible ya que entienden que es una decisión importante. Además si quieres hacer una reforma tienen un equipo adjunto para asesorarte y proponerte posibilidades que ofrece el inmueble.            
            </p>
          </Slide>
          </Carousel>  
        </div>
      </div>
    </div>
    </section>

    <section class="pb70">
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <p class="text-center" style="font-size:36px;font-weight:800;color:#38383A;">
                    La inmobiliaria donde hacemos que las  <br>cosas pasen.
                </p>
                <br>
                <p class="text-center" style="font-size:18px;color:#747474;">
                    Te ayudamos a encontrar una casa con todo lo que buscas. Lista para entrar a vivir o <br>para reformar con los acabados que tu elijas.
                </p>
                <br>
                <div class="row mt-5 justify-content-center mb-5">

                        <router-link to="/encontrar-un-hogar" class="btn py-3 px-5 orange-button">Te ayudamos a encontrar tu casa ideal.</router-link>

                </div>
            </div>
        </div>
        </div>
    </section>   
</div>
</template>
<style>
    #banner1{
        background:url(../assets/static/fondo-casas.png);
        overflow-x: none;
    }
    .intro {
        background: url(../assets/static/table.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 860px;
    }
    .intro-content{
        padding-top:225px;
    }
    @media (max-width: 992px) {
        .intro-content{
           padding-top:100px; 
        }
     }
    .form{
        background-color: white;
        padding:20px;
        border-radius:30px;
    }
</style>
<script>
import engineFields from '@/engine_fields.json'
import Multiselect from 'vue-multiselect'
import { Carousel, Slide } from 'vue-carousel';
export default {

    name:'Sell',
    components: {
        Multiselect,
        Carousel,
        Slide
    },
    data(){
        return {
            prop_tipos:engineFields.tipos,
            tipo:null,
            prop_operacion: [{'name':'Vender'},{'name':'Alquilar'}],
            operacion:null,
            ciudad:'',
            nombre:'',
            tel:'',
            email:'',
            contact_check:false,
            error_form:'',
            message_form:''
        }
    },
    methods:{
        async sendContact(){
            var myPhoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/;
            var myEmailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
            let is_valid_name = (this.nombre!=''&&this.nombre.length>0)
            let is_valid_tel = (this.tel.length>=9 && myPhoneRegex.test(this.tel))
            let is_valid_email = (this.email.length>=3 && myEmailRegex.test(this.email))

            if(!is_valid_name){
                this.error_form = 'El nombre de contacto no debe ser vacío' 
                this.message_form = '';
                return;
            }
            if(!is_valid_tel){
                this.error_form = 'El teléfono introducido no es válido' 
                this.message_form = '';
                return;
            }
            if(!is_valid_email){
                this.error_form = 'El formato del email es inválido' 
                this.message_form = '';
                return;
            }

            if(this.tipo == null){
                this.error_form = 'No se ha seleccionado ningún tipo de inmueble' 
                this.message_form = '';
                return;
            }
            if(this.operacion == null){
                this.error_form = 'Seleccione la operación' 
                this.message_form = '';
                return;
            }

            if(!this.contact_check){
                this.error_form = 'Acepte los Términos y condiciones de privacidad' 
                this.message_form = '';
                return;
            }

            let cookies = {
                "favorites":$cookies.get("avanza_favorite_properties"),
                "last_visited":$cookies.get("avanza_visited_properties")
            }
            let body = {
                "name":this.nombre,
                "phone":this.tel,
                "email":this.email,
                "message":'',
                "operation":this.operacion.name,
                "property_type":this.tipo.tipo,
                "city":this.ciudad,
                "cookie_values":JSON.stringify(cookies)
            }
            let json = JSON.stringify(body)
            console.log(json)
            this.axios.post('/web/catchment',json,{
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                this.message_form = response.data.msg;
                this.error_form = '';
                this.tipo=null
                this.operacion=null
                this.ciudad=''
                this.nombre=''
                this.tel=''
                this.email=''
                this.contact_check=false
            }).catch(error=>{
                this.error_form = 'Ha habido un error en el envío del formulario, intentelo de nuevo más tarde.'
                this.message_form = '';
            });

        }
    }
}
</script>
