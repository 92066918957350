<template>
    <div style="height: 350px; ">
    <l-map
      style="height: 80%; width: 100%; z-index:1"
      :zoom="zoom"
      :center="center"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
      @update:bounds="boundsUpdated"
    >
        <l-circle
        :lat-lng="circle_center"
        :radius="radius"
        color="Darkblue"
        />
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    </l-map>
  </div>
</template>
<script>
    import {LMap, LTileLayer, LCircle} from 'vue2-leaflet';
    export default {
        
        name: 'Map',
        components: {
            LMap,
            LTileLayer,
            LCircle
        },
        props: {
            altitude:Number,
            latitude:Number,
            radius:Number,
            zoom:Number
        },
        data(){
            return{
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                center: null,
                bounds: null,
                circle_center:null
            }
        },
        created(){
            this.center = [this.latitude,this.altitude]
            this.circle_center = [this.latitude,this.altitude]
        },
        methods:{
            zoomUpdated (zoom) {
            this.zoom = zoom;
            },
            centerUpdated (center) {
            this.center = center;
            },
            boundsUpdated (bounds) {
            this.bounds = bounds;
            }
        }
    }
</script>