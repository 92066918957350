<template>
  <div class="property" >
    <div v-if="error" class="alert alart_style_three alert-dismissible fade show" role="alert" style="position:fixed;z-index:4;bottom:5px;left:5px;"> {{error_msg}}
      <button type="button" class="close"  @click="error=false"> <span aria-hidden="true">×</span> </button>
    </div>
    <CoolLightBox 
      :items="fotos" 
      :index="image_index"
      @close="image_index = null">
    </CoolLightBox>  
    <!-- Listing Single Property -->
  <section class="listing-title-area mb-1 pb-3">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-8">
          <div class="row">
            <div class="col-lg-12">
              <div class="spls_style_two mb30-767">
                <a @click="image_index = 0">
                  <img class="img-fluid d-none d-md-block" :src="fotos[0]" style="height:510px !important; width:100%;object-fit:cover!important">
                  <img class="img-fluid d-block d-md-none" :src="fotos[0]" style="height:250px !important; width:100%;object-fit:cover!important">
                </a>
                <a href="#"><span class="baddge_right">{{ficha.acciones}}</span></a>
              </div>
            </div>
          </div>
          <div class="row justify-content-start d-md-none d-flex" style="padding:0 15px 0 15px">
              <div class="col-3 px-1">
                <div class="spls_style_two bg-light" >
                  <a v-if="fotos.length>1" @click="image_index = 1"><img v-if="fotos.length>1" class="img-fluid w100 h280" style="height:50px !important; object-fit:cover!important" :src="fotos[1]"></a>
                </div>
              </div>
              <div class="col-3 px-1">
                <div class="spls_style_two bg-light" >
                  <a v-if="fotos.length>2" @click="image_index = 2"><img v-if="fotos.length>2" class="img-fluid w100 h280" style="height:50px !important; object-fit:cover!important" :src="fotos[2]"></a>
                </div>
              </div>
              <div class="col-3 px-1">
                <div class="spls_style_two bg-light" >
                  <a v-if="fotos.length>3" @click="image_index = 3"><img v-if="fotos.length>3" class="img-fluid w100 h280" style="height:50px !important; object-fit:cover!important" :src="fotos[3]"></a>
                </div>
              </div>
              <div class="col-3 px-1">
                <div class="spls_style_two bg-light" >
                  <a v-if="fotos.length>4 " @click="image_index = 4"><img v-if="fotos.length>4" class="img-fluid w100 h280" style="height:50px !important; object-fit:cover!important" :src="fotos[4]"></a>
                </div>
              </div>
            </div>
        </div>
        
        
        <div class="col-md-5 col-lg-4 d-md-block d-none">
          <div class="row">
            <div class="col-sm-4 col-md-6 col-lg-6 pl0 pr5 pr15-767 pl15-767">
              <div class="spls_style_two mb10 bg-light" >
                <a v-if="fotos.length>1" @click="image_index = 1"><img v-if="fotos.length>1" class="img-fluid w100 h280" style="height:250px !important; object-fit:cover!important" :src="fotos[1]"></a>
              </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-6 pl0 pr5 pr15-767 pl15-767">
              <div class="spls_style_two mb10 bg-light " >
                <a v-if="fotos.length>2" @click="image_index = 2"><img v-if="fotos.length>2" class="img-fluid w100 h280" style="height:250px !important; object-fit:cover!important" :src="fotos[2]"></a>
              </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-6 pl0 pr5 pr15-767 pl15-767">
              <div class="spls_style_two mb10 bg-light " >
                <a v-if="fotos.length>3" @click="image_index = 3"><img v-if="fotos.length>3" class="img-fluid w100 h280" style="height:250px !important; object-fit:cover!important" :src="fotos[3]"></a>
              </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-6 pl0 pr5 pr15-767 pl15-767">
              <div class="spls_style_two mb10 bg-light" >
                <a v-if="fotos.length > 4 " @click="image_index = 4"><img v-if="fotos.length > 4 " class="img-fluid w100 h280" style="height:250px !important; object-fit:cover!important" :src="fotos[4]"></a>
                <div class="overlay" @click="image_index = 4" v-if="fotos.length > 4 ">
                  <h3 class="title" v-if="fotos.length > 4 ">+4</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Agent Single Grid View -->
  <section class="our-agent-single pt0 pb70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <div class="row">
            <div class="col-xl-8">
              <div class="single_property_title mt30-767">
                <div class="media">
                  <div class="media-body">
                    <h2 class="mt-0">{{titulo}}</h2>
                    <p>{{ficha.ciudad}} ({{ficha.cp}})</p>
                    <ul class="prop_details mb0">
                      <li class="list-inline-item mr20"><b class="mr20" href="#"></span>REF: {{ficha.ref}}</b></li>
                      <li class="list-inline-item mr20" v-if="ficha.total_hab"><a class="mr20" href="#"><span class="flaticon-bed pr5 vam"></span> {{ficha.total_hab}} Habitaciones</a></li>
                      <li class="list-inline-item mr20" v-if="ficha.banyos"><a class="mr20" href="#"><span class="flaticon-bath pr5 vam"></span> {{ficha.banyos}} Baños</a></li>
                      <li class="list-inline-item mr20" v-if="ficha.m_cons"><a class="mr20" href="#"><span class="flaticon-ruler pr5 vam"></span> {{ficha.m_cons}}	&#13217;</a></li>
                      <li class="list-inline-item mr20" v-if="ficha.antiguedad"><a href="#"><span class="flaticon-calendar pr5 vam"></span> Año de construcción: {{ficha.antiguedad}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="single_property_social_share_content text-right tal-md tal-lg">
                <div class="spss style2 mt30">
                  <ul class="mb0">
                    <li class="list-inline-item icon"><span @click="share()" class="flaticon-share"></span></li>
                    <li class="list-inline-item" @click="share()">Compartir</li>
                    <li v-if="!favorite" class="list-inline-item icon" @click="addToFavorites()" ><span class="flaticon-heart-shape-outline"></span></li>
                    <li v-else class="list-inline-item icon"  style="background-color:#ECC8C5"><span class="flaticon-heart-shape-outline text-danger" @click="quitFromFavorites()"></span></li>
                    <li v-if="!favorite" class="list-inline-item" @click="addToFavorites()" >Marcar como favorito</li>
                    <li v-else class="list-inline-item text-danger" @click="quitFromFavorites()" >Quitar de favoritos</li>
                  </ul>
                </div>
                <div class="price mt20 mb10">
                <h3>
                  <small class="mr10" v-if="ficha.outlet">
                    <del>{{Math.ceil(ficha.outlet).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}}€</del>
                  </small>
                  {{(ficha.precioinmo!=0 && ficha.precioinmo != null)?Math.ceil(ficha.precioinmo).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')+'€':''}}
                  {{((ficha.precioinmo!=0 && ficha.precioinmo != null )&&(ficha.precioalq!=0 && ficha.precioalq != null))?' - ':''}}
                  {{(ficha.precioalq!=0 && ficha.precioalq != null)? Math.ceil(ficha.precioalq).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')+'€/MES' : ''}}
                </h3></div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="listing_single_description mt50 mb60">
                <h4 class="mb30">Descripción</h4>
                <p class="first-Description mb25" v-html="descr">{{descr}}</p>
              </div>
            </div>
            <!--
            <div class="col-lg-12">
              <div class="property_attachment_area pb40 bb1">
                <h4 class="mb15">Documents</h4>
                <ul class="document_list mb0">
                  <li class="list-inline-item"><span class="icon flaticon-file"></span>&nbsp;&nbsp;test_property.pdf <a class="tdu text-thm" href="#">Download</a></li>
                  <li class="list-inline-item"><span class="icon flaticon-file"></span>&nbsp;&nbsp;test_property.pdf <a class="tdu text-thm" href="#">Download</a></li>
                  <li class="list-inline-item"><span class="icon flaticon-file"></span>&nbsp;&nbsp;test_property.pdf <a class="tdu text-thm" href="#">Download</a></li>
                </ul>
              </div>
            </div>-->
            <div class="col-lg-12">
              <div class="location_details pb40 mt50 bb1">
                <div class="row">
                  <div class="col-lg-12">
                    <h4 class="mb15">Ubicación</h4>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item mb0">
                      <li><p>Ciudad:</p></li>
                    </ul>
                    <ul class="list-inline-item mb0">
                      <li><p><span>{{ficha.ciudad}}</span></p></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item mb0">
                      <li><p>Zona:</p></li>
                    </ul>
                    <ul class="list-inline-item mb0">
                      <li><p><span>{{ficha.zona}}</span></p></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item mb0">
                      <li><p>CP:</p></li>
                    </ul>
                    <ul class="list-inline-item mb0">
                      <li><p><span>{{ficha.cp}}</span></p></li>
                    </ul>
                  </div>
                  <div class="col-lg-12">
                    <div class="property_sp_map mt30">
                      <div class="h400 bdrs3" id="map-canvas">
                       <Map v-if="this.latitud!=0 && this.altitud!=0"
                       :latitude="parseFloat(this.latitud)" 
                       :altitude="parseFloat(this.altitud)" 
                       :radius="2000" 
                       :zoom="12"></Map>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="additional_details pb40 mt50 bb1">
                <div class="row">
                  <div class="col-lg-12">
                    <h4 class="mb15">Características</h4>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item">
                      <li><p>Referencia:</p></li>
                      <li><p>Operación:</p></li>
                      <li><p>Tipo propiedad:</p></li>
                      <li v-if="ficha.m_uties && ficha.m_uties>0"><p>Superficie Útil:</p></li>
                    </ul>
                    <ul class="list-inline-item">
                      <li><p><span>{{ficha.ref}}</span></p></li>
                      <li><p><span>{{ficha.acciones}}</span></p></li>
                      <li><p><span>{{ficha.nbtipo}}</span></p></li>
                      <li v-if="ficha.m_uties && ficha.m_uties>0"><p><span>{{ficha.m_uties}} m&#178;</span></p></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item">
                      <li v-if="ficha.nbconservacion"><p>Conservación :</p></li>
                      <li v-if="ficha.antiguedad"><p>Antigüedad :</p></li>
                      <li v-if="ficha.banyos"><p>Baños :</p></li>
                      <li v-if="ficha.aseos"><p>Aseos :</p></li>
                      
                      <li v-if="ficha.m_cons && ficha.m_cons>0"><p>Superficie Construida:</p></li>
                    </ul>
                    <ul class="list-inline-item">
                      <li v-if="ficha.nbconservacion"><p><span>{{ficha.nbconservacion}}</span></p></li>
                      <li v-if="ficha.antiguedad"><p><span>{{ficha.antiguedad}}</span></p></li>
                      <li v-if="ficha.banyos"><p><span>{{ficha.banyos}}</span></p></li>
                      <li v-if="ficha.aseos"><p><span>{{ficha.aseos}}</span></p></li>
                      
                      <li v-if="ficha.m_cons && ficha.m_cons>0"><p><span>{{ficha.m_cons}} m&#178;</span></p></li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-6 col-xl-4">
                    <ul class="list-inline-item">
                      <li v-if="ficha.habitaciones" ><p>Habitaciones :</p></li>
                      <li v-if="ficha.nborientacion"><p>Orientación:</p></li>
                      <li v-if="ficha.m_parcela && ficha.m_parcela>0"><p>Superficie Construida:</p></li>
                    </ul>
                    <ul class="list-inline-item">
                      <li v-if="ficha.habitaciones"><p><span>{{ficha.habitaciones}}</span></p></li>
                      <li v-if="ficha.nborientacion"><p><span>{{ficha.nborientacion}}</span></p></li>
                      <li v-if="ficha.m_parcela && ficha.m_parcela>0"><p>{{ficha.m_parcela}} m&#178;</p></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="additional_details pb40 mt50 bb1">
                <div class="row">
                  <div class="col-lg-12">
                    <h4 class="mb30">Entorno y calidades</h4>
                  </div>
                  <div v-if="ficha.ascensor==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-up-arrow"></span></div>
                      <div class="details">
                        <div class="title">Ascensor</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.airecentral==1 || ficha.aire_con==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-air-conditioner"></span></div>
                      <div class="details">
                        <div class="title">Aire acondicionado</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.alarma==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-house"></span></div>
                      <div class="details">
                        <div class="title">Alarma</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.arma_empo==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-refrigerator"></span></div>
                      <div class="details">
                        <div class="title">Armarios empotrados</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.balcon==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-blinds"></span></div>
                      <div class="details">
                        <div class="title">Balcon</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.plaza_gara<0 || ficha.garajedoble==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-car"></span></div>
                      <div class="details">
                        <div class="title">Garaje</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.jardin==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-grass"></span></div>
                      <div class="details">
                        <div class="title">Jardín</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.luminoso==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-checked"></span></div>
                      <div class="details">
                        <div class="title">Luminoso</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 9))==Math.pow(2, 9)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-street-view"></span></div>
                      <div class="details">
                        <div class="title">Autobuses</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 14))==Math.pow(2, 14)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-place"></span></div>
                      <div class="details">
                        <div class="title">Céntrico</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 0))==Math.pow(2, 0)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-grass"></span></div>
                      <div class="details">
                        <div class="title">Árboles</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 15))==Math.pow(2, 15)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-blinds"></span></div>
                      <div class="details">
                        <div class="title">Centro médico</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 13))==Math.pow(2, 13)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-dumbbell"></span></div>
                      <div class="details">
                        <div class="title">Colegios</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 7))==Math.pow(2, 7)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-outdoor-shower"></span></div>
                      <div class="details">
                        <div class="title">Costa</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 1))==Math.pow(2, 1)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-coaxial"></span></div>
                      <div class="details">
                        <div class="title">Hospitales</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 16))==Math.pow(2, 16)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-chair"></span></div>
                      <div class="details">
                        <div class="title">Zona de paso</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="(ficha.x_entorno&Math.pow(2, 12))==Math.pow(2, 12)" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-chair"></span></div>
                      <div class="details">
                        <div class="title">Zonas infantiles</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ficha.vistasalmar==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-chair"></span></div>
                      <div class="details">
                        <div class="title">Vistas al mar</div>
                      </div>
                    </div>
                  </div><div v-if="ficha.vistasdespejadas==1" class="col-sm-6 col-xl-3">
                    <div class="listing_feature_iconbox mb30">
                      <div class="icon float-left mr10"><span class="flaticon-chair"></span></div>
                      <div class="details">
                        <div class="title">Vistas despejadas</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--
            <div class="col-lg-12">
              <div class="property_sp_videos pb40 mt50 bb1">
                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="property-tab" data-toggle="tab" href="#property" role="tab" aria-controls="property" aria-selected="true">Property Video</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="virtual-tab" data-toggle="tab" href="#virtual" role="tab" aria-controls="virtual" aria-selected="false">360° Virtual Tour</a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent2">
                  <div class="tab-pane fade show active" id="property" role="tabpanel" aria-labelledby="property-tab">
                    <div class="listing_single_video">
                      <div class="property_video">
                        <div class="thumb"><img class="pro_img img-fluid w100" src="images/listing/lspv.jpg" alt="lspv.jpg">
                          <div class="overlay_icon"><a class="video_popup_btn popup-youtube" href="https://www.youtube.com/watch?v=oqNZOOWF8qM"><span class="flaticon-play-button"></span></a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="virtual" role="tabpanel" aria-labelledby="virtual-tab">
                    <div class="listing_single_video">
                      <div class="property_video">
                        <div class="thumb"><img class="pro_img img-fluid w100" src="images/listing/lspv.jpg" alt="lspv.jpg">
                          <div class="overlay_icon"><a class="video_popup_btn popup-youtube" href="https://www.youtube.com/watch?v=oqNZOOWF8qM"><span class="flaticon-play-button"></span></a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
            <div class="col-lg-12 mb-5">
              <div class="energy_class mt50">
                <h4 class="mb20">Certificación Energética</h4>
                <table class="table-bordered table-eficiencia" cellpadding="0" cellspacing="0" border="1">
                    <tbody>
                    <tr>
                        <td style="text-align:center;vertical-align:middle;font-weight:bold;font-size:18px;" width="200">
                           <span v-if="ficha.energialetra=='tramites'  || ficha.emisionesletra=='tramites'">En trámites</span> 
                        </td>
                        <td class="tac t1 c1" width="100" style="font-size:12px;padding:5px;">Consumo de Energía<br> kWh/m<sup>2</sup> Año</td>
                        <td class="tac t1 c1" width="100" style="font-size:12px;padding:5px;">Emisiones Kg CO2/m2 Año</td>
                    </tr>
                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaA"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'A'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'A'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaB"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'B'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'B'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaC"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'C'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'C'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaD"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'D'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'D'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaE"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'E'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'E'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaF"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'F'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'F'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td bgcolor="#FFFFFF"><div class="eficienciaG"></div></td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.energialetra == 'G'" class="flechaEficiencia">{{ficha.energiavalor}}</div>
                        </td>
                        <td bgcolor="#FFFFFF">
                          <div v-if="ficha.emisionesletra == 'G'" class="flechaEficiencia">{{ficha.emisionesvalor}}</div>
                        </td>
                    </tr>
                    </tbody>
              </table>
              </div>
            </div>
            <div class="col-lg-12" v-if="ficha.precioinmo && ficha.precioinmo != 0">
              <div class="chart_circle_doughnut pb50 mt50 bb1">
                <h4 class="mb20">Calculadora de hipoteca</h4>
                <div class="property_sp_payment_calculator">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Precio:</label>
                        <div class="input-group">
                          <input type="number" class="form-control" v-model="hipo_precio">
                          <div class="input-group-append">
                            <span class="input-group-text">€</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Localización:</label>
                        <select class="custom-select muted text-muted mb-3" v-model="hipo_comu">
                          <option value="1">Andalucía</option>
                          <option value="2">Aragón</option>
                          <option value="3">Asturias</option>
                          <option value="4">Baleares</option>
                          <option value="5">Canarias</option>
                          <option value="6">Cantabria</option>
                          <option value="7">Castilla-La Mancha</option>
                          <option value="8">Castilla León</option>
                          <option value="9">Cataluña</option>
                          <option value="10">Ceuta</option>
                          <option value="11">C.Valenciana</option>
                          <option value="12">C.Valenciana (Red.)</option>
                          <option value="13">C.Valenciana (Sup.)</option>
                          <option value="14">Extremadura</option>
                          <option value="15">Galicia</option>
                          <option value="16">La Rioja</option>
                          <option value="17">Madrid</option>
                          <option value="18">Melilla</option>
                          <option value="19">Murcia</option>
                          <option value="20">Murcia (Red.)</option>
                          <option value="21">Navarra</option>
                          <option value="22">País Vasco</option>
                        </select> 
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Ahorro aportado:</label>
                        <div class="input-group">
                          <input type="number" class="form-control" v-model="hipo_aportado">
                          <div class="input-group-append">
                            <span class="input-group-text">€</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Estado:</label>
                        <select class="custom-select muted text-muted mb-3" v-model="hipo_estado">
                          <option value="0">Segunda Mano</option>
                          <option value="1">Nuevo</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Años:</label>
                        <input type="number" class="form-control" v-model="hipo_anyos">
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="">Tipo interés anual:</label>
                        <div class="input-group">
                          <input type="number" class="form-control" v-model="hipo_tipo">
                          <div class="input-group-append">
                            <span class="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      Impuestos de la compra ({{getTax}} %): <b>{{getTotalTax}} €</b> <br>
                      Total con impuestos: <b>{{getTotalWithTax}} €</b> <br>
                      <br>
                      <h4 class="text-dark">
                        Cuota: {{getMortgage}} €/Mes
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-3">
          <div class="listing_single_sidebar">
            <div class="sidebar_agent_search_widget mb30">
              <div class="agent_search_form">
                  <div class="alert alart_style_three fade show" role="alert" v-if="error_form!=''"> {{error_form}}</div>
                  <div class="alert alart_style_one fade show" role="alert" v-if="message_form!=''"> {{message_form}}</div>
                  <label for="">Nombre:</label>
                  <div class="form-group input-group mb10">
                    <input type="text" class="form-control" v-model="contact_name" maxlength="80">
                  </div>
                  <label for="">Teléfono:</label>
                  <div class="form-group input-group mb10">
                    <input type="text" class="form-control" v-model="contact_phone" maxlength="15">
                  </div>
                  <label for="">Email:</label>
                  <div class="form-group input-group mb10">
                    <input type="email" class="form-control" v-model="contact_email" maxlength="80">
                  </div>
                  <label for="">Mensaje:</label>
                  <div class="form-group input-group mb10">
                    <textarea class="form-control" rows="10" style="height:80px!important" v-model="contact_message" maxlength="255"></textarea>
                  </div>
                  <div class="ui_kit_checkbox">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="contact_check">
                      <label class="custom-control-label" for="customCheck1">He leído y acepto los <router-link to="politica-de-privacidad"> términos y condiciones de privacidad</router-link></label>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-sm btn-block btn-thm mb10 orange-button" @click="sendContact()">Enviar Mensaje</button>
                  <!--<button type="submit" class="btn btn-block btn-whatsapp mb0">WHATSAPP</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>
<style>
.eficienciaA {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 0 transparent;
    height: 33px !important;
    width: 100px;
}
.eficienciaB {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -34px transparent;
    height: 33px !important;
    width: 116px;
}
.eficienciaC {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -69px transparent;
    height: 33px !important;
    width: 133px;
}
.eficienciaD {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -104px transparent;
    height: 33px !important;
    width: 150px;
}
.eficienciaE {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -140px transparent;
    height: 33px !important;
    width: 166px;
}
.eficienciaF {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -175px transparent;
    height: 33px !important;
    width: 183px;
}
.eficienciaG {
    background: url(../assets/efi/eficiencia.png) no-repeat scroll 0 -209px transparent;
    height: 33px !important;
    width: 200px;
}
.flechaEficiencia {
    background-color: #222;
    color: #FFFFFF;
    display: block;
    float: left;
    font-size: 22px;
    font-weight: bold;
    height: 30px !important;
    line-height: 32px;
    margin-right: 10px;
    padding: 0;
    text-align: center;
    width: 100%;
}
.flechaEficiencia:after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #FFFFFF #222 #FFFFFF #FFFFFF;
    border-image: none;
    border-style: solid;
    border-width: 15px;
    content: "";
    display: block;
    float: left;
    height: 0;
    position: relative;
    width: 0;
}
</style>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Map from '@/components/Map.vue'

export default {
  name: 'Property',
  components: {
    CoolLightBox,
    Map,
  },
  data(){
    return{
      id: -1,
      inmueble: {},
      titulo: '',
      descr: '',
      ficha:{},
      fotos:[],
      image_index:null,
      video:{},
      requested: false,
      latitud: 0,
      altitud: 0,
      favorite:false,
      error:false,
      error_msg:'',
      error_form:'',
      message_form:'',

      contact_name:'',
      contact_phone:'',
      contact_email:'',
      contact_message:'',
      contact_check:false,

      hipo_precio:0,
      hipo_impuesto:0,
      hipo_aportado:0,
      hipo_anyos:30,
      hipo_tipo:3,
      hipo_comu:11,
      hipo_estado:0,
      itp:[
        {'id':1,'comunidad': 'Andalucía','valor_nuevo':0.1,'valor_usado':0.07},
        {'id':2,'comunidad': 'Aragón','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':3,'comunidad': 'Asturias','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':4,'comunidad': 'Baleares','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':5,'comunidad': 'Canarias','valor_nuevo':0.07,'valor_usado':0.1},
        {'id':6,'comunidad': 'Cantabria','valor_nuevo':0.1,'valor_usado':0.1},
        {'id':7,'comunidad': 'Castilla-La Mancha','valor_nuevo':0.1,'valor_usado':0.09},
        {'id':8,'comunidad': 'Castilla León','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':9,'comunidad': 'Cataluña','valor_nuevo':0.1,'valor_usado':0.1},
        {'id':10,'comunidad':'Ceuta','valor_nuevo':0.1,'valor_usado':0.06},
        {'id':11,'comunidad':'C.Valenciana','valor_nuevo':0.1,'valor_usado':0.1},
        {'id':12,'comunidad':'C.Valenciana (Red.)','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':13,'comunidad':'C.Valenciana (Sup.)','valor_nuevo':0.1,'valor_usado':0.04},
        {'id':14,'comunidad':'Extremadura','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':15,'comunidad':'Galicia','valor_nuevo':0.1,'valor_usado':0.1},
        {'id':16,'comunidad':'La Rioja','valor_nuevo':0.1,'valor_usado':0.07},
        {'id':17,'comunidad':'Madrid','valor_nuevo':0.1,'valor_usado':0.06},
        {'id':18,'comunidad':'Melilla','valor_nuevo':0.1,'valor_usado':0.06},
        {'id':19,'comunidad':'Murcia','valor_nuevo':0.1,'valor_usado':0.08},
        {'id':20,'comunidad':'Murcia (Red.)','valor_nuevo':0.1,'valor_usado':0.03},
        {'id':21,'comunidad':'Navarra','valor_nuevo':0.1,'valor_usado':0.06},
        {'id':22,'comunidad':'País Vasco','valor_nuevo':0.1,'valor_usado':0.06}
      ]
    }
  },
  created(){
    window.scrollTo(0, 0);
    this.getInmueble(this.$route.params.id)
    this.favorite = this.isInFavorites(parseInt(this.$route.params.id))
  },
  methods:{
    async getInmueble(id){
      const inmuebleResponse = await this.axios.get('/inmovilla/inmueble/'+id);
      this.id = id
      this.ficha = inmuebleResponse.data.ficha['1']
      this.fotos = inmuebleResponse.data.fotos[id]
      this.video = inmuebleResponse.data.videos
      this.requested = true
      this.titulo = inmuebleResponse.data.descripciones != null ? inmuebleResponse.data.descripciones[id]['1'].titulo : this.ficha['nbtipo']+' en '+this.ficha['ciudad']+' - '+this.ficha['zona']
      this.descr = inmuebleResponse.data.descripciones != null ? inmuebleResponse.data.descripciones[id]['1'].descrip.replace(/~/g,"<br/>") : ''
      this.center = [this.ficha.latitud,this.ficha.altitud]
      this.setCookie({
        "id":this.id,
        "ficha":this.ficha,
        "fotos":this.fotos
      })
      this.latitud = this.ficha.latitud,
      this.altitud = this.ficha.altitud
      this.hipo_precio = this.ficha.precioinmo
      this.hipo_aportado = this.ficha.precioinmo*0.2
    },
    setCookie(property){
      let timestamp = new Date()
      let props_json = {
        "idProp":property.id,
        "image":property.fotos[0],
        "tipo":property.ficha.nbtipo,
        "dorm":property.ficha.total_hab,
        "banyos":property.ficha.banyos,
        "sup":property.ficha.m_cons,
        "referencia":property.ficha.ref,
        "precioInmo":property.ficha.precioinmo,
        "precioAlq":property.ficha.precioalq,
        "precioCom":property.ficha.outlet,
        "ciudad":property.ficha.ciudad,
        "zona":property.ficha.zona,
        "numFotos":property.fotos.length,
        "operacion":property.ficha.acciones,
        "tipoAlq":property.ficha.tipomensual,
        "timestamp":timestamp.toISOString()
      }
      console.log(props_json)
      let historic = $cookies.isKey("avanza_visited_properties") ? $cookies.get("avanza_visited_properties") : [];
      if(historic.length>=5){
        historic.shift()
      }
      historic.push(props_json)
      let new_historic = JSON.stringify(historic)
      $cookies.set("avanza_visited_properties",new_historic)
    },
    share(){
      navigator.share({
        title: 'Comparte este inmueble',
        text: '¡No te lo pierdas!: '+this.titulo+' en AVANZA INMUEBLES',
        url: window.location.href
      })
    },
    addToFavorites(){
        let timestamp = new Date()
        let props_json = {
        "idProp":parseInt(this.id),
        "image":this.fotos[0],
        "tipo":this.ficha.nbtipo,
        "dorm":this.ficha.total_hab,
        "banyos":this.ficha.banyos,
        "sup":this.ficha.m_cons,
        "referencia":this.ficha.ref,
        "precioInmo":this.ficha.precioinmo,
        "precioAlq":this.ficha.precioalq,
        "precioCom":this.ficha.outlet,
        "ciudad":this.ficha.ciudad,
        "zona":this.ficha.zona,
        "numFotos":this.fotos.length,
        "operacion":this.ficha.acciones,
        "tipoAlq":this.ficha.tipomensual,
        "timestamp":timestamp.toISOString()
      }

        let favs = $cookies.isKey("avanza_favorite_properties") ? $cookies.get("avanza_favorite_properties") : [] ;
        if(favs.length < 8){
            favs.push(props_json)
            let new_favs = JSON.stringify(favs)
            $cookies.set("avanza_favorite_properties",new_favs);
            this.favorite=true;
        }else{
            this.error_msg="No se pueden seleccionar mas de 10 propiedades favoritas"
            this.error=true
        }
        
    },
    quitFromFavorites(){
        let favs = $cookies.isKey("avanza_favorite_properties") ? $cookies.get("avanza_favorite_properties") : [] ;
        favs = favs.filter(prop => prop.idProp != this.id)
        let new_favs = JSON.stringify(favs)
        $cookies.set("avanza_favorite_properties",new_favs);
        this.favorite=false;
    },
    isInFavorites(id){
        if($cookies.isKey("avanza_favorite_properties")){
            let favs = $cookies.get("avanza_favorite_properties").map(x => x.idProp)
            console.log(favs)
            if(favs.includes(id)){
                return true;
            }
        }
        return false;
    },
    async sendContact(){
      var myPhoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/;
      var myEmailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
      let is_valid_name = (this.contact_name!=''&&this.contact_name.length>0)
      let is_valid_tel = (this.contact_phone.length>=9 && myPhoneRegex.test(this.contact_phone))
      let is_valid_email = (this.contact_email.length>=3 && myEmailRegex.test(this.contact_email))
      if(!is_valid_name){
        this.error_form = 'El nombre de contacto no debe ser vacío' 
        this.message_form = '';
        return;
      }
      if(!is_valid_tel){
        this.error_form = 'El teléfono introducido no es válido' 
        this.message_form = '';
        return;
      }
      if(!is_valid_email){
        this.error_form = 'El formato del email es inválido' 
        this.message_form = '';
        return;
      }
      if(!this.contact_check){
        this.error_form = 'Acepte los Términos y condiciones de privacidad' 
        this.message_form = '';
        return;
      }
      let cookies = {
          "favorites":$cookies.get("avanza_favorite_properties"),
          "last_visited":$cookies.get("avanza_visited_properties")
        }
      let body = {
          "name":this.contact_name,
          "phone":this.contact_phone,
          "email":this.contact_email,
          "message":this.contact_message,
          "operation":this.ficha.acciones,
          "property_type":this.ficha.nbtipo,
          "city":this.ficha.ciudad,
          "min_price":this.ficha.precioalq ? this.ficha.precioalq : 0 ,
          "max_price":this.ficha.precioinmo,
          "property_ref":this.ficha.ref,
          "cookie_values":JSON.stringify(cookies)
        }
      let json = JSON.stringify(body)

      this.axios.post('/web/lead',json,{
        headers: {
            'Content-Type': 'application/json',
        }
      }).then(response => {
        this.message_form = response.data.msg;
        this.error_form = '';
      }).catch(error=>{
        this.error_form = 'Ha habido un error en el envío del formulario, intentelo de nuevo más tarde.'
        this.message_form = '';
      });
    }
  },
  computed:{
    getMortgage(){
      var cuotas = this.hipo_anyos*12
      var impuesto = (this.hipo_estado==1)? this.itp[this.hipo_comu-1].valor_nuevo : this.itp[this.hipo_comu-1].valor_usado ;
      var total = ((this.hipo_precio * (1+impuesto)) - this.hipo_aportado)
      return Math.round((total*((this.hipo_tipo/100)/12))/(1-( Math.pow((1+((this.hipo_tipo/100)/12)),(-1*cuotas)) )) )
    },
    getTax(){
      return (this.hipo_estado==1)? (this.itp[this.hipo_comu-1].valor_nuevo)*100 : (this.itp[this.hipo_comu-1].valor_usado)*100 
    },
    getTotalWithTax(){
      var impuesto = (this.hipo_estado==1)? this.itp[this.hipo_comu-1].valor_nuevo : this.itp[this.hipo_comu-1].valor_usado ;
      return this.hipo_precio * (1+impuesto)
    },
    getTotalTax(){
      var impuesto = (this.hipo_estado==1)? this.itp[this.hipo_comu-1].valor_nuevo : this.itp[this.hipo_comu-1].valor_usado ;
      return this.hipo_precio * impuesto
    }
  }
}
</script>
