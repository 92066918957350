<template>
  <div class="container my-5">
    <h2>
        Política de cookies
    </h2>
    <p>
      DEFINICIÓN DE COOKIES
      <br><br>
      Las cookies son identificadores que enviamos al disco duro de su ordenador a través de su navegador Web con el fin de que nuestros sistemas puedan reconocer su navegador y ofrecerle ciertos servicios. En general, estas tecnologías pueden servir para finalidades muy diferentes, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido. Esta página utiliza tipos diferentes de cookies. Algunas cookies son colocadas por servicios de terceros que aparecen en nuestras páginas. Las cookies pueden ser:
      <br><br>
      Cookies de preferencias
      Las cookies de preferencias permiten a la página web recordar información que cambia la forma en que la página se comporta o el aspecto que tiene, como su idioma preferido o la región en la que usted se encuentra.
      <br><br>
      Cookies de estadísticas
      Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima. Para deshabilitar las cookies de Google Analytics, puede instalar el Complemento inhabilitación para navegadores de Google Analytics que le permitirá no mandar información estadística. Lo tiene disponible en el siguiente enlace: https://tools.google.com/dlpage/gaoptout?hl=es
      <br><br>
      Cookies de marketing
      Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.
      <br><br>
      CóMO DESHABILITAR LAS COOKIES EN LOS PRINCIPALES NAVEGADORES:
      <br><br>
      Normalmente es posible dejar de aceptar las Cookies del navegador, o dejar de aceptar las Cookies de un Servicio en particular.
      <br><br>
      Todos los navegadores modernos permiten cambiar la configuración de Cookies. Estos ajustes normalmente se encuentra en las 'opciones' o 'Preferencias' del menú de su navegador. Asimismo, puede configurar su navegador o su gestor de correo electrónico.
      <br><br>
      A continuación se ofrece orientación al Usuario sobre los pasos para acceder al menú de configuración de las cookies y, en su caso, de la navegación privada en cada uno de los navegadores principales:
      <br><br>
      Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.
      <br><br>
      Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada. Para más información, puede consultar el soporte de Mozilla o la Ayuda del navegador.
      <br><br>
      Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de contenido. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.
      <br><br>
      Safari: Preferencias -> Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.
      <br><br>
      ¿Qué ocurre si se deshabilitan las Cookies?
      <br><br>
      Algunas funcionalidades de los Servicios quedarán deshabilitados.
      <br><br>
      Cookies usadas:
      <br><br>
      A continuación, indicamos las cookies que utiliza la página:
      <br><br>
    </p>
    <table class="table">
  <thead>
    <tr>
      <th scope="col">Nombre</th>
      <th scope="col">Tipo</th>
      <th scope="col">Descripción/Finalidad</th>
      <th scope="col">Duración</th>
      <th scope="col">Propietario</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        avanza_visited_properties,
        avanza_favorite_properties
      </td>
      <td>Cookies de preferencias - Analítica</td>
      <td>Esta cookie se utiliza para guardar las propiedades vistas recientemente, favoritas.</td>
      <td>30 días</td>
      <td>Avanza</td>
    </tr>
  </tbody>
</table>
    
  </div>
</template>
<style>
</style>
<script>

export default {
  name: 'Privacidad',
  components: {
  },
  data(){
    return{
    }
  },
}
</script>
