<template>
  <div class="container my-5">
    <h2>
        Aviso Legal
    </h2>
        <br><br>
        <h4>Política de privacidad y protección de datos</h4>
        <br>

        De acuerdo con lo establecido por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), el cliente/usuario queda informado y presta su consentimiento expreso a la incorporación de sus datos a un fichero del que es responsable AVANZA NUEVOS DESARROLLOS COOP.V. con la finalidad de informarle sobre los productos y servicios solicitados, así como el envío de comunicaciones comerciales sobre los mismos. Le informamos también sobre sus derechos de acceso, rectificación, cancelación y oposición, que podrá ejercer en el domicilio social de AVANZA NUEVOS DESARROLLOS COOP.V., sito en Avenida Constitución nº 104 Entlo. – B de Villena (Alicante), con CIF F54650114.

        Le informamos también que los datos personales suministrados no serán cedidos ni comunicados, ni siquiera para su conservación, a terceras personas.

        Toda nuestra infraestructura está alojada en España, por lo que se garantiza que no hay transferencias internacionales de datos. Además tenemos las medidas de seguridad necesarias para el nivel de seguridad que corresponde a los datos que manejamos, conforme con las directrices de la Agencia Española de Protección de Datos.


        <br><br>
        <h4>0. Objeto y aceptación</h4>
        <br>

        El presente aviso legal regula el uso del siguiente sitio web
        www.avanzainmuebles.com
        ,  (en adelante, LA WEB), de la que es titular AVANZA NUEVOS DESARROLLOS COOP.V. (en adelante, EL PROPIETARIO DE LA WEB).

        La finalidad del tratamiento de tus datos personales recabados a través de cualquiera de los sitios web referidos, podrán ser las siguientes;
        Por la contratación de cualquiera de nuestros servicios ofertados a través de cualquiera de los sitios web y posibilitar mantener la relación contractual, así como la gestión, administración, información, prestación y mejora del servicio.
        Posibilitar el envío de información solicitada a través de los formularios dispuestos en el sitio web o a través de plataformas de envío de email masivo
        Remitir boletines, newsletter, comunicaciones comerciales sobre servicios y promociones, publicidad del sitio web y del sector concreto de cualquiera de las webs aquí referidas.
        La navegación por cualquiera de los sitios web de EL PROPIETARIO DE LA WEB atribuye la condición de usuario del mismo e implica la aceptación plena, expresa y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal, que pueden sufrir modificaciones.
        El usuario se obliga a hacer un uso correcto del sitio web de conformidad con las leyes, la buena fe, el orden público, los usos del tráfico y el presente Aviso Legal. El usuario responderá frente a EL PROPIETARIO DE LA WEB o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.

        Los datos facilitados a través de la web se conservarán mientras se mantenga la relación comercial o no se solicite expresamente su supresión de forma fehaciente a través de cualquiera de los medios que se facilitan en este aviso legal.

        <br><br>
        <h4>1. Identificación y comunicaciones</h4>
        <br>

        EL PROPIETARIO DE LA WEB, en cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que:
        Su denominación social es: AVANZA NUEVOS DESARROLLOS COOP.V.. Su CIF/NIF/NIE es: F54650114. Su domicilio social está en: Avenida Constitución nº 104 Entlo. – B de Villena (Alicante)
        Inscrita en el Registro de Cooperativas de Trabajo Asociado, A-1.709 con fecha 1 de octubre de 2012.

        Para comunicarse con nosotros, ponemos a su disposición diferentes medios de contacto que detallamos a continuación:
        Por correo postal a la dirección; Avenida Constitución nº 104 Entlo. – B de Villena (Alicante)
        Tfno: 96 611 95 54
        Email: info@avanzainmuebles.com

        Todas las notificaciones y comunicaciones entre los usuarios y EL PROPIETARIO DE LA WEB se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal o cualquier otro medio de los detallados anteriormente.
        
        <br><br>
        <h4>2. Condiciones de acceso y utilización</h4>
        <br>

        El sitio web y sus servicios son de acceso libre y gratuito para los usuarios, no obstante, EL PROPIETARIO DE LA WEB condiciona la utilización de algunos de los servicios ofrecidos en su web a la previa cumplimentación del correspondiente formulario.

        El usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique a EL PROPIETARIO DE LA WEB y será el único responsable de las manifestaciones falsas o inexactas que realice.

        El usuario se compromete expresamente a hacer un uso adecuado de los contenidos y servicios de EL PROPIETARIO DE LA WEB y a no emplearlos para, entre otros:

        a) Difundir contenidos, delictivos, violentos, pornográficos, racistas, xenófobo, ofensivos, de apología del terrorismo o, en general, contrarios a la ley o al orden público.
        b) Introducir en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de EL PROPIETARIO DE LA WEB o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales EL PROPIETARIO DE LA WEB presta sus servicios.
        c) Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de EL PROPIETARIO DE LA WEB o de terceros y, en su caso, extraer información.
        d) Vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de EL PROPIETARIO DE LA WEB o de terceros.
        e) Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero.
        f) Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido.
        g) Recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie su previa solicitud o consentimiento.

        Todos los contenidos del sitio web, como textos, fotografías, gráficos, imágenes, iconos, tecnología, software, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a EL PROPIETARIO DE LA WEB, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web.

        En definitiva, los usuarios que accedan a este sitio web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación.

        Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de EL PROPIETARIO DE LA WEB, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.

        La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación quedan prohibidos. El establecimiento de un hiperenlace no implica en ningún caso la existencia de relaciones entre EL PROPIETARIO DE LA WEB y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de EL PROPIETARIO DE LA WEB de sus contenidos o servicios. Aquellas personas que se propongan establecer un hiperenlace previamente deberán solicitar autorización por escrito a EL PROPIETARIO DE LA WEB. En todo caso, el hiperenlace únicamente permitirá el acceso a la home-page o página de inicio de nuestro sitio web, asimismo deberá abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre EL PROPIETARIO DE LA WEB, o incluir contenidos ilícitos, contrarios a las buenas costumbres y al orden público.

        EL PROPIETARIO DE LA WEB no se responsabiliza del uso que cada usuario le dé a los materiales puestos a disposición en este sitio web ni de las actuaciones que realice en base a los mismos.

        <br><br>
        <h4>3. Exclusión de garantías y de responsabilidad</h4>
        <br>

        El contenido del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.

        EL PROPIETARIO DE LA WEB excluye, hasta donde permite el ordenamiento jurídico, cualquier responsabilidad por los daños y perjuicios de toda naturaleza derivados de:

        a) La imposibilidad de acceso al sitio web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición a los que se haya accedido a través del sitio web o de los servicios que se ofrecen.
        b) La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.
        c) El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto del sitio web. En particular, y a modo ejemplificativo, EL PROPIETARIO DE LA WEB no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad ilícita.
        Asimismo, EL PROPIETARIO DE LA WEB declina cualquier responsabilidad respecto a la información que se halle fuera de esta web y no sea gestionada directamente por nuestro webmaster. La función de los links que aparecen en esta web es exclusivamente la de informar al usuario sobre la existencia de otras fuentes susceptibles de ampliar los contenidos que ofrece este sitio web. EL PROPIETARIO DE LA WEB no garantiza ni se responsabiliza del funcionamiento o accesibilidad de los sitios enlazados; ni sugiere, invita o recomienda la visita a los mismos, por lo que tampoco será responsable del resultado obtenido. EL PROPIETARIO DE LA WEB no se responsabiliza del establecimiento de hipervínculos por parte de terceros.

        <br><br>
        <h4>4. Política de privacidad</h4>
        <br>

        En cumplimiento de lo previsto en artículo 13 del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, le informamos de que sus datos personales serán incorporados a nuestros tratamientos de datos, debidamente registrados y que podrán ser cedidos cuando legalmente corresponda. Sus datos serán empleados para la atención de las consultas, observaciones, prestación de servicios relacionados con la temática de la web y reservas que nos remita o efectúe a través de esta web y otras labores de promoción de nuestros servicios, que puntualmente se puedan llevar a cabo.

        Se garantiza al titular de los datos el ejercicio de los derechos de acceso, rectificación, cancelación y oposición de los datos que le conciernen, debiendo para ello, dirigirse mediante comunicación escrita a la siguiente dirección postal que figura en el apartado “Información sobre el tratamiento de datos” o a la siguiente dirección electrónica: info@avanzainmuebles.com.

        El titular de los datos resulta informado y consiente en la comunicación y tratamiento de sus datos personales bajo las debidas condiciones de seguridad y secreto profesional, por el período que resulte necesario para la finalidad para la que son recabados, tratados y cedidos.

        Información sobre el tratamiento de datos


        A fin de dar cumplimiento a lo dispuesto en el Reglamento General de Protección de Datos de la UE, el usuario debe recibir información puntual y concreta del responsable del tratamiento y los usos y fines del mismo. Para ello, se informa de los siguientes aspectos:

        Responsable


        Razón social:
        AVANZA NUEVOS DESARROLLOS, COOP.V.
        CIF:
        F-54650114
        Dirección:
        Avenida Constitución nº 104 Entlo. – B de Villena (Alicante)
        Correo electrónico:
        info@avanzainmuebles.com
        Teléfono:
        96 611 95 54
        DPD:
        Rafael Balaguer Ibáñez
        Contacto DPD:
        info@avanzainmuebles.com

        Finalidad


        Gestión de consultas y demanda de información por parte del usuario. La información será mantenida un plazo máximo de 24 meses, mientras no exista la petición expresa de supresión por parte del usuario. Pasado este plazo, la información se almacenará de forma indefinida.

        Legitimación


        Consentimiento del afectado.

        Destinatarios


        No se prevén cesiones.

        Derechos


        Todos los derechos recogidos en la normativa vigente en materia de privacidad.
        Derecho de ACCESO a tus datos personales
        Derechos de solicitar la RECTIFICACIÓN de los datos inexactos
        Derecho de solicitar su SUPRESIÓN cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.
        En determinadas circunstancias, podrás solicitar la LIMITACIÓN DEL TRATAMIENTO de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
        En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán ejercitar su derecho de OPOSICIÓN al tratamiento de sus datos. Dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
        En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás solicitar su derecho a la PORTABILIDAD de los datos.
        Podrás ejercer tales derechos dirigiendo una comunicación a la dirección postal y/o de correo electrónico que se hace constar en el presente comunicado.
        Además, en caso de que se haya vulnerado cualquiera de tus derechos, el interesado tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD), en C/ Jorge Juan, 6, 28001-Madrid o a través de la sede electrónica de la AEPD: https://sedeagpd.gob.es/sede-electronica-web/ 
        Procedencia de los datos


        El propio usuario.

        Alojamiento de datos


        El sitio web objeto de esta política de privacidad se encuentra alojado dentro de la Unión Europea y que satisface los requerimientos en la normativa en materia de privacidad en el momento de la redacción de esta política.

        Menores de edad


        Consideramos que la protección de datos de carácter personal es esencial, por lo que en el supuesto de que el usuario sea menor de edad, éste sólo podrá aportar sus datos personales con el previo consentimiento de los padres o tutores.

        No se gestionaran en ningún caso datos de menores de los que no se tenga autorización y consentimiento de padres o tutores legales.

        Actualizaciones y cambios en la política de privacidad


        El propietario de este sitio web puede modificar esta Política de Privacidad en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los Usuarios que la visiten periódicamente.
        
        <br><br>
        <h4>5. Procedimiento en caso de realización de actividades de carácter ilícito</h4>
        <br>

        En el caso de que cualquier usuario o un tercero considere que existen hechos o circunstancias que revelen el carácter ilícito de la utilización de cualquier contenido y/o de la realización de cualquier actividad en las páginas web incluidas o accesibles a través del sitio web, deberá enviar una notificación a EL PROPIETARIO DE LA WEB identificándose debidamente, especificando las supuestas infracciones y declarando expresamente y bajo su responsabilidad que la información proporcionada en la notificación es exacta.

        Para toda cuestión litigiosa que incumba al sitio web de EL PROPIETARIO DE LA WEB, será de aplicación la legislación española, siendo competentes los Juzgados y Tribunales de VILLENA (Alicante) - (España).
        <br><br>
        <h4>6. Publicaciones</h4>
        <br>

        La información administrativa facilitada a través del sitio web no sustituye la publicidad legal de las leyes, normativas, planes, disposiciones generales y actos que tengan que ser publicados formalmente a los diarios oficiales de las administraciones públicas, que constituyen el único instrumento que da fe de su autenticidad y contenido. La información disponible en este sitio web debe entenderse como una guía sin propósito de validez legal.

        Este documento fue actualizado por última vez el 10 de mayo de 2018.
</div>
</template>
<style>
</style>
<script>

export default {
  name: 'AvisoLegal',
  components: {
  },
  data(){
    return{
    }
  },
}
</script>
