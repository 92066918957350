<template>
  <div class="container my-5">
    <h2>
        Política privacidad
    </h2>
    <p>
        <b>Responsable:</b> AVANZA NUEVOS DESARROLLOS, COOP. V.
        <br>
        <br>
        <b>Finalidad:</b> Gestión de las solicitudes de información a través de la página web con la finalidad de prestarles servicios profesionales inmobiliarios y así facilitarles información sobre lo que solicite.
        <br>
        <br>
        <b>Legitimación:</b> La legitimación se basa en el consentimiento que usted nos otorga al clicar el botón "Acepto la política de protección de datos".
        <br>
        <br>
        <b>Destinatarios:</b> Sus datos no se cederán a nadie, salvo obligación legal.
        <br>
        <br>
        <b>Derechos:</b> Podrá ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y retirada de consentimiento de sus datos personales en la dirección de correo electrónico info@avanzainmuebles.com
    </p>
  </div>
</template>
<style>
</style>
<script>

export default {
  name: 'Privacidad',
  components: {
  },
  data(){
    return{
    }
  },
}
</script>
